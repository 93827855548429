import { ApolloError } from 'apollo-boost';
import Vue from 'vue';

import { getClient } from '@/services/client';
import { UserModelWithAlbums } from '@/models/user.model';
import Loader from '@/components/Shared/Loader/Loader';
import '../ClientView.css';
import AlbumList from '@/components/Shared/AlbumList/AlbumList';
import firebase from '@/services/firebase';
import { sortAlphabetically } from '@/services/utils';

export interface ClientAlbumsData {
  client: UserModelWithAlbums | null;
  loading: boolean;
}

const ClientAlbums = Vue.extend({
  computed: {
    totalPictures() {
      const allAlbums = this.client?.albums || [];

      const total: number = allAlbums.reduce(
        (prevVal, curVal) => (curVal.pictures?.length || 0) + prevVal,
        0,
      );

      return total.toLocaleString();
    },
  },

  data(): ClientAlbumsData {
    return {
      client: null,
      loading: true,
    };
  },

  methods: {
    loadClient() {
      this.loading = true;

      const email = firebase.auth().currentUser?.email || '';

      getClient(
        '',
        email,
      )
        .then(({ data }) => {
          const client = data?.getClient || null;

          if (client) {
            const albums = sortAlphabetically(client.albums, 'name');

            this.client = {
              ...client,
              albums,
            };
          }
        })
        .catch((err: ApolloError) => {
          const errMessage = err.graphQLErrors.map(({ message }) => message).join(' ');

          // Redirect admins to the dashboard
          if (errMessage === 'IS_ADMIN') {
            this.$router.push('/admin/dashboard');
            return;
          }
          console.log('Error loading client => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadClient();
  },

  name: 'client-albums',

  render() {
    if (this.loading) {
      return (
        <Loader />
      );
    }

    if (!this.client) {
      return (
        <h1>Error loading client details.</h1>
      );
    }

    return (
      <div class="client-view">

        <div class="logo">
          <router-link to="/client/albums">
            <img src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663321/Teefal%20Studios/logo.png' alt="Teefal Logo" />
          </router-link>
        </div>

        <header class="header">
          <h1 class="title">{this.client.firstName} {this.client.lastName} Albums</h1>

          <div class="icon-pairs">
            <div class="icon-pair">
              <img class="icon-pair-image" src={require('@/components/Icons/link.png')} alt="Link icon" />
              <span class="icon-pair-value">{this.totalPictures}</span>
              <span class="icon-pair-title">Pictures</span>
            </div>
          </div>
        </header>

        <div class="content">

          <AlbumList
            albums={this.client.albums}
            linkSuffix='/client/albums'
          />

        </div>

      </div>
    );
  },

  watch: {
    $route: 'loadClient',
  },
});


export default ClientAlbums;
